<template>
  <quill-editor v-model="content" :options="editorOption"></quill-editor>
</template>

<script>
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor /* { default global options } */)
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: '',

      val: 'something',
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [
              'blockquote',
              'code-block',
              'link',
              { list: 'ordered' },
              { list: 'bullet' },
              { align: [] },
              { header: [1, 2, 3, 4, 5, 6, false] }
            ]
          ]
        }
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.content = val
      },
      immediate: true
    },
    content(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style></style>
